.rbc-current-time-indicator {
    background-color: #F32A23;
    height: 3px;
}

.rbc-timeslot-group {
    height: 45px;
}

.rbc-today {
    background-color: #ffffff;
}

.rbc-toolbar {
    font-size: 20px;
}

.rbc-btn-group > button {
    font-size: 20px;
}

.rbc-event-label {
    font-size: 100%;
}
